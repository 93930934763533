<script setup lang="ts">
const { modalOpen, data } = useMandatoryTraining()
const { hasFeature } = useAuth()

const mandatoryTraining = computed(() => {
  return [
    ...(data.value?.courses ?? []),
    ...(data.value?.videos ?? []),
    ...(data.value?.policies ?? []),
  ]
})

const nextMandatoryTraining = computed(() => {
  if (mandatoryTraining.value.length == 0) {
    return
  }

  const trainingWithNearestDeadline = [...mandatoryTraining.value].sort(
    (a, b) => new Date(a.deadline).getTime() - new Date(b.deadline).getTime(),
  )[0]

  return trainingWithNearestDeadline
})

const { trackEvent } = useAnalytics()

function handleLinkClicked() {
  modalOpen.value = false

  trackEvent("mandatory_training_modal_clicked")
}
</script>

<template>
  <div class="fixed right-0 bottom-0 z-20 m-4">
    <Transition
      enter-active-class="duration-300 ease-out"
      enter-from-class="opacity-0 scale-90"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="duration-300 ease-in"
      leave-from-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
      appear
    >
      <LayoutCard
        v-if="modalOpen && nextMandatoryTraining"
        class="w-80 bg-slate-900! shadow-lg! ring-slate-950!"
      >
        <div class="mb-4 flex justify-between">
          <TypographyHeading :level="4" class="text-white">{{
            $t("mandatory.next_training")
          }}</TypographyHeading>
          <ProgressCloseButton
            :duration="8000"
            dark
            @click="modalOpen = false"
            @progress-finished="modalOpen = false"
          />
        </div>
        <MandatoryContentCourseItem
          v-if="nextMandatoryTraining.type == 'course-type'"
          dark
          :content="nextMandatoryTraining.content"
          :deadline="nextMandatoryTraining.deadline"
          placement="mandatory"
          @link-clicked="handleLinkClicked"
        />
        <MandatoryContentVideoItem
          v-else-if="nextMandatoryTraining.type == 'video-type'"
          dark
          :content="nextMandatoryTraining.content"
          :deadline="nextMandatoryTraining.deadline"
          placement="mandatory"
          @link-clicked="handleLinkClicked"
        />
        <MandatoryContentPolicyItem
          v-else-if="
            nextMandatoryTraining.type == 'policy-type' &&
            hasFeature('hub-policy')
          "
          dark
          :content="nextMandatoryTraining.content"
          :deadline="nextMandatoryTraining.deadline"
          placement="mandatory"
          @link-clicked="handleLinkClicked"
        />
      </LayoutCard>
    </Transition>
  </div>
</template>
