<script setup lang="ts">
const { message } = useFlashMessage()

watch(message, () =>
  setTimeout(() => (message.value = { text: undefined }), 5000),
)

const isBottomAligned = computed(() => message.value?.position === "bottom")
</script>

<template>
  <div
    class="pointer-events-none fixed right-0 left-0 z-100 mx-auto flex w-1/2 items-center justify-center text-white"
    :class="{
      'bottom-0': isBottomAligned,
      'top-0': !isBottomAligned,
    }"
  >
    <Transition
      appear
      enter-active-class="duration-200 ease-out"
      :enter-from-class="`transform opacity-0 ${isBottomAligned ? 'translate-y-40' : '-translate-y-40'}`"
      enter-to-class="opacity-200 translate-y-0"
      leave-active-class="duration-200 ease-in"
      leave-from-class="opacity-200 translate-y-0"
      :leave-to-class="`transform opacity-0 ${isBottomAligned ? 'translate-y-40' : '-translate-y-40'}`"
    >
      <div
        v-if="message.text"
        class="w-full bg-slate-800 shadow-sm sm:mx-auto sm:w-auto sm:max-w-full sm:min-w-fit"
        :class="{
          'sm:rounded-tl sm:rounded-tr': isBottomAligned,
          'sm:rounded-br sm:rounded-bl': !isBottomAligned,
        }"
      >
        <div
          class="flex w-full flex-row items-center justify-start overflow-hidden px-4 py-3"
        >
          <component
            :is="message.icon"
            v-if="message.icon"
            :class="['mr-2 inline-block size-5', message.iconClass]"
          />
          <SvgoPikaLightning
            v-else
            class="star-pulse text-point-yellow mr-2 inline-block size-5"
          />
          <p class="mb-0">{{ message.text }}</p>
        </div>
      </div>
    </Transition>
  </div>
</template>

<style scoped>
.star-pulse {
  animation: star-pulse 2s infinite;
}

@-webkit-keyframes star-pulse {
  0% {
    filter: drop-shadow(0px 0px 1px #bf9b30);
  }
  70% {
    filter: drop-shadow(0px 0px 2rem #bf9b30);
  }
  100% {
    filter: drop-shadow(0px 0px 3rem #bf9b30);
  }
}
@keyframes star-pulse {
  0% {
    filter: drop-shadow(0px 0px 1px #bf9b30);
  }
  70% {
    filter: drop-shadow(0px 0px 2rem #bf9b30);
  }
  100% {
    filter: drop-shadow(0px 0px 3rem #bf9b30);
  }
}
</style>
